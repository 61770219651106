import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';
import EthersProvider from './providers/EthersProvider';

import store from './store';
import theme from './theme';

import { getCookie } from './hooks/hookCookies.';
import { getUser, setUserToAuthorized } from './store/actions/userAction';

import App from './App';

if (getCookie) {
  store.dispatch(setUserToAuthorized());
  store.dispatch(getUser());
}

const root = document.getElementById('root');

if (root !== null) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              hideIconVariant
            >
              <EthersProvider askOnLoad={false}>
                <App />
              </EthersProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    root,
  );
}
