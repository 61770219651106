/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ethers } from 'ethers';

import web3Modal from '../lib/web3Modal';
import getLocalEnv from '../utils/getLocalEnv';

import EthersContext from '../context/EthersContext';
import api from '../utils/appApi';

const ETHEREUM = window?.ethereum;

const { chainHex, chainName } = getLocalEnv();

const EthersProvider = ({ children, askOnLoad = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);

  const { isAuthorized, isAdmin } = useSelector((state) => ({
    isAuthorized: state.user?.isAuthorized,
    isAdmin: state.user?.data?.role === 'admin',
  }));

  useEffect(() => {
    if (ETHEREUM) {
      ETHEREUM?.on('accountsChanged', ([newAddress]) => {
        setAddress(ethers.utils.getAddress(newAddress));
      });
    }
  }, [ETHEREUM]);

  const connect = async () => {
    try {
      if (web3Modal.cachedProvider) {
        web3Modal.clearCachedProvider();
      }
      const web3Instance = await web3Modal.connect();

      const providerInstance = new ethers.providers.Web3Provider(web3Instance);
      const network = await providerInstance.getNetwork();
      const signerUser = providerInstance.getSigner();
      const userAddress = await signerUser.getAddress();

      setAddress(userAddress);
      setChainId(network.chainId);
      setProvider(providerInstance);
      setSigner(signerUser);
      setIsConnected(true);

      return {
        provider: providerInstance,
        address: userAddress,
        chainId: network.chainId,
        signer: signerUser,
      };
    } catch (error) {
      const err = error?.data?.message ?? error?.message;
      if (err) {
        const cancelled = /user rejected/i.test(err);
        enqueueSnackbar(cancelled ? 'Wallet connection was cancelled!' : err, {
          variant: 'error',
        });
      } else {
        console.error(error);
      }

      return null;
    }
  };

  const disconnect = () => {
    setIsConnected(false);
    setAddress(null);
    setChainId(null);
    setProvider(null);
  };

  const toggleConnection = useCallback(
    async () => (isConnected ? disconnect() : connect()),
    [isConnected],
  );

  const switchNetwork = async (source = 'components', network = chainHex) => {
    try {
      await ETHEREUM?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network }],
      });
    } catch (error) {
      const message = `Please connect to the ${chainName} network`;

      enqueueSnackbar(message, { variant: 'error' });

      if (source === 'components') {
        throw new Error(message);
      }
    }
  };

  const ethersContextValue = useMemo(
    () => ({
      ethers,
      web3Modal,
      address,
      chainId,
      provider,
      signer,
      isConnected,
      isMetamaskInstalled: Boolean(ETHEREUM),
      ethersProvider: {
        connect,
        disconnect,
        toggleConnection,
        switchNetwork,
      },
    }),
    [
      ethers,
      web3Modal,
      address,
      chainId,
      provider,
      signer,
      isConnected,
      connect,
      disconnect,
      switchNetwork,
    ],
  );

  useEffect(() => {
    if (!ETHEREUM) {
      enqueueSnackbar('Please consider installing Metamask.', {
        variant: 'warning',
      });
      return;
    }
    if (askOnLoad) {
      switchNetwork('onLoad');
    }
  }, []);

  useEffect(() => {
    if (isAdmin || !isConnected) return;
    if (!isAuthorized) return disconnect();

    api.user
      .postWallet({ address })
      .then(() => {
        enqueueSnackbar('Connected', {
          variant: 'success',
          autoHideDuration: 1500,
        });
      })
      .catch((error) => {
        disconnect();
        enqueueSnackbar(
          error.response?.data?.message ?? error.message ?? 'Unknown error',
          { variant: 'error' },
        );
      });
  }, [isConnected, isAuthorized, isAdmin, address]);

  return (
    <EthersContext.Provider value={ethersContextValue}>
      {children}
    </EthersContext.Provider>
  );
};

export default EthersProvider;
