import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/rootReducer';
import getLocalEnv from 'utils/getLocalEnv';

const { environment } = getLocalEnv();

const storeCreation = () => {
  const configureStore = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk)),
  );

  // Configure redux for hot-reload development
  if (environment !== 'production' && module.hot) {
    module.hot.accept('./reducers/rootReducer', () => {
      configureStore.replaceReducer(rootReducer);
    });
  }

  return configureStore;
};

const store = storeCreation();

export default store;
