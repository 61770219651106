import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { removeCookie } from '../hooks/hookCookies.';
import { setUserToNotAuthorized } from '../store/actions/userAction';

const Logout = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    removeCookie('token');
    localStorage.removeItem('isAuthorized');
    navigate('/');
    setUserToNotAuthorized()(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
