import { useEffect, useRef, lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Logout from './containers/Logout';
import NoMatch from './containers/NoMatch';

const AdminLogin = lazy(() => import('./containers/admin/AdminLogin'));
const AdminDashboard = lazy(() => import('./containers/admin/AdminDashboard'));
const UserPage = lazy(() => import('./containers/admin/UserPage'));
const UsersList = lazy(() => import('./containers/admin/UsersList'));
const ContestCreationPage = lazy(() =>
  import('./containers/admin/ContestCreationPage'),
);
const ContestsPage = lazy(() => import('./containers/ContestsPage'));
const Dashboard = lazy(() => import('./containers/Dashboard'));
const HomePage = lazy(() => import('./containers/HomePage'));
const Layout = lazy(() => import('./components/Layout'));

import Loading from './components/Loading';

function App() {
  let mount = useRef(true);

  const { isAuthorized, isAdmin } = useSelector((state) => ({
    isAuthorized: state.user?.isAuthorized,
    isAdmin: state.user?.data?.role === 'admin',
  }));

  useEffect(() => {
    mount.current = false;
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<Layout />}>
          <Route
            index
            element={
              <Navigate to={isAuthorized ? '/dashboard' : '/contests'} />
            }
          />
          <Route path="/contests">
            <Route index element={<HomePage />} />
            <Route path=":contestId" element={<ContestsPage />} />
          </Route>

          {isAuthorized && (
            <Route
              path="/dashboard"
              element={isAdmin ? <AdminDashboard /> : <Dashboard />}
            />
          )}

          <Route path="/admin">
            <Route index element={<AdminLogin />} />
            <Route index path="login" element={<AdminLogin />} />
            {isAdmin && (
              <>
                <Route path="contests">
                  <Route index element={<ContestCreationPage />} />
                  <Route
                    path=":contestId"
                    element={<ContestCreationPage isEditing />}
                  />
                </Route>
                <Route path="users" element={<UsersList />} />
                <Route path="users/:userId" element={<UserPage />} />
              </>
            )}
          </Route>

          <Route path="/logout" element={<Logout />} />
        </Route>

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
}

export default App;
