import { LOGIN, LOGOUT, GET_USER } from '../actionTypes';

const initialState = {
  isAuthorized: false,
  token: null,
  data: {
    id: null,
    discordId: null,
    username: '',
    email: '',
    discriminator: '',
    avatar: '',
    role: '',
    password: '',
    token: '',
    refreshToken: '',
    createdAt: null,
    updatedAt: null,
  },
};

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthorized: true,
        token: action.payload,
        // data: {
        //   ...state.data,
        //   ...action.payload.user,
        // },
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case GET_USER:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default user;
