import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

export const getCookie = Cookies.get('token');
export const setCookie = (value) => Cookies.set('token', value);
export const removeCookie = () => Cookies.remove('token');

export default function isTokenExpired() {
  if (getCookie) return Date.now() <= jwt_decode(getCookie)?.exp * 1000;
  return null;
}
