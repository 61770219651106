import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import ProximaNovaLight from './assets/fonts/ProximaNova/ProximaNova-Light.otf';
import ProximaNovaLightItalic from './assets/fonts/ProximaNova/ProximaNova-LightItalic.otf';
import ProximaNovaRegular from './assets/fonts/ProximaNova/ProximaNova-Regular.otf';
import ProximaNovaRegularItalic from './assets/fonts/ProximaNova/ProximaNova-RegularItalic.otf';
import ProximaNovaSemibold from './assets/fonts/ProximaNova/ProximaNova-Semibold.otf';
import ProximaNovaSemiboldItalic from './assets/fonts/ProximaNova/ProximaNova-SemiboldItalic.otf';
import ProximaNovaBold from './assets/fonts/ProximaNova/ProximaNova-Bold.otf';
import ProximaNovaBoldIt from './assets/fonts/ProximaNova/ProximaNova-BoldIt.otf';
import ProximaNovaExtrabold from './assets/fonts/ProximaNova/ProximaNova-Extrabold.otf';
import ProximaNovaBlack from './assets/fonts/ProximaNova/ProximaNova-Black.otf';
import GTUltraotf from './assets/fonts/GT-Ultra/GT-Ultra-Standard-Ultra.otf';
import GTUltrattf from './assets/fonts/GT-Ultra/GT-Ultra-Standard-Ultra.ttf';
import GTUltrawoff from './assets/fonts/GT-Ultra/GT-Ultra-Standard-Ultra.woff';
import GTUltrawoff2 from './assets/fonts/GT-Ultra/GT-Ultra-Standard-Ultra.woff2';
import LinkBehavior from './components/LinkBehavior';

const headingFamily = ['"GT Ultra"', 'sans-serif'].join(',');

const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F9A137',
      dark: '#101014',
      darkly: '#18181e',
    },
    secondary: {
      main: '#2BD9FF',
      contrastText: '#140E3B',
      blue: '#5978EE',
    },
    error: {
      main: '#F54867',
    },
    warning: {
      main: '#FEF653',
    },
    info: {
      main: '#53E5FF',
    },
  },
  typography: {
    fontFamily: ['"Proxima Nova"', 'sans-serif'].join(','),
    h1: { fontFamily: headingFamily },
    h2: { fontFamily: headingFamily },
    h3: { fontFamily: headingFamily },
    h4: { fontFamily: headingFamily },
    h5: { fontFamily: headingFamily },
    h6: { fontFamily: headingFamily },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        backgroundColor: '#151515',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: 320,
          background: theme.palette.primary.dark,
          color: theme.palette.common.white,
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          minWidth: 320,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 300;
            font-style: normal;
            src: local('Proxima Nova'),
                url(${ProximaNovaLight}) format('opentype');
        }
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 300;
            font-style: italic;
            src: local('Proxima Nova'),
                url(${ProximaNovaLightItalic}) format('opentype');
        }
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 400;
            font-style: normal;
            src: local('Proxima Nova'),
                url(${ProximaNovaRegular}) format('opentype');
        }
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 400;
            font-style: italic;
            src: local('Proxima Nova'),
                url(${ProximaNovaRegularItalic}) format('opentype');
        }
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 600;
            font-style: normal;
            src: local('Proxima Nova'),
                url(${ProximaNovaSemibold}) format('opentype');
        }
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 600;
            font-style: italic;
            src: local('Proxima Nova'),
                url(${ProximaNovaSemiboldItalic}) format('opentype');
        }
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 700;
            font-style: normal;
            src: local('Proxima Nova'),
                url(${ProximaNovaBold}) format('opentype');
        }
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 700;
            font-style: italic;
            src: local('Proxima Nova'),
                url(${ProximaNovaBoldIt}) format('opentype');
        }
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 800;
            font-style: normal;
            src: local('Proxima Nova'),
                url(${ProximaNovaExtrabold}) format('opentype');
        }
        @font-face {
            font-family: 'Proxima Nova';
            font-weight: 900;
            font-style: normal;
            src: local('Proxima Nova'),
                url(${ProximaNovaBlack}) format('opentype');
        }
        
        // GT Ultra
        @font-face {
            font-family: 'GT Ultra';
            src: local('GT Ultra'),
                url(${GTUltraotf}) format('opentype'),
                url(${GTUltrattf}) format('truetype'),
                url(${GTUltrawoff}) format('woff'),
                url(${GTUltrawoff2}) format('woff2');
        }
        body {
            background-color: #101014;
            color: #fff;
            font-size: 18px;   
        }
       `,
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightBold,
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'black',
          color: 'white',
        },
      },
    },
  },
});

export default responsiveFontSizes(defaultTheme);
