import React from 'react';

import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const NoMatch = () => {
  return (
    <Container maxWidth="lg">
      <Toolbar />
      <ListItemText sx={{ textAlign: 'center', justifyContent: 'center' }}>
        <Typography component="h4" variant="h1">
          404
        </Typography>
        <Typography component="h4" variant="h4">
          {`There's no place like `}
          <Link to="/">HOME</Link>
        </Typography>
      </ListItemText>
    </Container>
  );
};

export default NoMatch;
