import { createContext } from 'react';
import { ethers } from 'ethers';
import web3Modal from 'lib/web3Modal';

const isMetamaskInstalled = Boolean(window?.ethereum);

export const EthersContext = createContext({
  ethers,
  web3Modal,
  isMetamaskInstalled,
  ethersProvider: null,
  address: null,
  chainId: null,
  provider: null,
  signer: null,
  isConnected: false,
});

export default EthersContext;
