import axiosInstance from './apiConfig';

const api = {
  // csrf: {
  //   get: () => axiosInstance.get('/auth/csrf-cookie'),
  // },
  admin: {
    login: (data) => axiosInstance.post('/admin/auth/login', data),
    get: () => axiosInstance.get('/auth/user'),
    createPool: (data) => axiosInstance.post('/admin/contests', data),
    getPools: (params) => axiosInstance.get('/admin/contests', { params }),
    getPool: (id) => axiosInstance.get(`/admin/contests/${id}`),
    updatePool: (id, data) => axiosInstance.put(`/admin/contests/${id}`, data),
    deletePool: (id) => axiosInstance.delete(`/admin/contests/${id}`),
    getUsers: (params) => axiosInstance.get(`/admin/users`, { params }),
    getUser: (id) => axiosInstance.get(`/admin/users/${id}`),
    addReward: (data) => axiosInstance.post('/admin/rewards', data),
    getRewards: (queryParams = {}) =>
      axiosInstance.get('/admin/rewards', {
        params: {
          order: 'DESC',
          page: 1,
          take: 10,
          ...queryParams,
        },
      }),
    deleteReward: (id) => axiosInstance.delete(`/admin/rewards/${id}`),
    getBatch: () => axiosInstance.post('/admin/rewards/batch'),
    signBatch: (signature) =>
      axiosInstance.post('/admin/rewards/signature', { signature }),
  },
  user: {
    getPools: (params) => axiosInstance.get('/contests', { params }),
    getPool: (id) => axiosInstance.get(`/contests/${id}`),
    postVoting: (data) => axiosInstance.post('/votes', data),
    getRewards: () => axiosInstance.get('/rewards'),
    claimRewards: (rewardsIds) =>
      axiosInstance.post('/rewards', { rewardsIds }),
    postWallet: (address) => axiosInstance.post('/wallet', address),
  },
};

export default api;
