import { LOGIN, LOGOUT, GET_USER } from '../actionTypes';

import api from '../../utils/appApi';
import axiosInstance from '../../utils/apiConfig';

import { getCookie, removeCookie, setCookie } from '../../hooks/hookCookies.';

export const setUserToAuthorized = () => (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: getCookie,
  });
  localStorage.setItem('isAuthorized', 'true');
};

export const setUserToNotAuthorized = () => (dispatch) => {
  localStorage.removeItem('isAuthorized');
  axiosInstance.defaults.headers.Authorization = 'Bearer ';
  dispatch({
    type: LOGOUT,
    payload: {},
  });
};

export const login = (values) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.admin
      .login(values)
      .then((res) => {
        const { data } = res;

        setCookie(data);
        localStorage.setItem('isAuthorized', 'true');
        axiosInstance.defaults.headers.Authorization = `Bearer ${data}`;
        dispatch({
          type: LOGIN,
          payload: data,
        });
        getUser()(dispatch);
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });

export const getUser = () => async (dispatch) =>
  api.admin
    .get()
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      if (
        err?.response?.data?.statusCode === 401 &&
        err?.response?.data?.message === 'Unauthorized'
      ) {
        localStorage.removeItem('isAuthorized');
        delete axiosInstance.defaults.headers['Authorization'];
        removeCookie();
        dispatch({
          type: LOGOUT,
          payload: {},
        });
      }
      return Promise.reject(err);
    });
