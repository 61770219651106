const getLocalEnv = () => ({
  environment: process.env.NODE_ENV,
  apiUrl: process.env.REACT_APP_API_URL,
  discordAuthUrl: process.env.REACT_APP_DISCORD_AUTH_URL,
  infuraId: process.env.REACT_APP_INFURA_ID,
  chainHex: process.env.REACT_APP_CHAIN_HEX,
  chainDec: process.env.REACT_APP_CHAIN_DECIMAL,
  chainName: process.env.REACT_APP_CHAIN_NAME,
  claimContractAddress: process.env.REACT_APP_CONTRACT,
  claimContractName: process.env.REACT_APP_CONTRACT_NAME,
  claimContractVersion: process.env.REACT_APP_CONTRACT_VERSION,
  wgmiContractAddress: process.env.REACT_APP_WGMI_ADDRESS,
  ethContractAddress: process.env.REACT_APP_ETH_ADDRESS,
});

export default getLocalEnv;
