import axios from 'axios';
import store from '../store/store';
import getLocalEnv from './getLocalEnv';
import { getUser, setUserToNotAuthorized } from '../store/actions/userAction';
import { getCookie } from '../hooks/hookCookies.';

const { apiUrl, environment } = getLocalEnv();

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getCookie}`,
  },
});

let isRefreshing = false;
const refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => refreshSubscribers.push(cb);

const onRefreshed = () => refreshSubscribers.forEach((cb) => cb());

axiosInstance.defaults.withCredentials = environment === 'production';

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response.data.message === 'CSRF token mismatch.') {
        if (!isRefreshing) {
          isRefreshing = true;
          /**
           * When CSRF token is expired or removed,
           * we just make get request and backend set new CSRF token.
           */
          store
            .dispatch(getUser())
            .then(() => {
              isRefreshing = false;
              onRefreshed();
            })
            .catch(() => {
              store.dispatch(setUserToNotAuthorized());
              window.location.reload();
            });
        }

        return new Promise((resolve) =>
          subscribeTokenRefresh(() => resolve(axios(error.config))),
        );
      } else if (error.response.data.message === 'Unauthenticated.') {
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
